import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Button } from "antd";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

// Load your Stripe publishable key
const stripePromise = loadStripe(
  "pk_test_51OaSZwImDgfPt27Sz27mjcqxbDLmpjZY4U8wECqWF3Lig6dJDMQdsnfvR92LvtKW0WslmNpbmMHMkgMGsT8OLRMK00B2YDk7Xt"
);

// Form component to handle card setup
const CardSetupForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    console.log(cardElement);

    // Confirm the card setup using the client secret from the server
    const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: "Customer Name", // You can dynamically set the customer name
        },
      },
    });

    if (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
    } else {
      setSuccess(true);
      setErrorMessage(null);
      console.log(
        "Payment method set up successfully:",
        setupIntent.payment_method
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-1/2">
      <CardElement />
      {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
      {success ? (
        <div style={{ color: "green" }}>Payment method setup successful!</div>
      ) : (
        <button
          type="submit"
          disabled={!stripe || isLoading}
          className="p-1 mt-4 text-white bg-blue-400 rounded-lg "
        >
          {isLoading ? "Processing..." : "Set up payment method"}
        </button>
      )}
    </form>
  );
};

// Main component that fetches the client secret and renders the form
const PaymentSetup = () => {
  const [clientSecret, setClientSecret] = useState(null);

  // Fetch the client secret from the backend when the component mounts
  React.useEffect(() => {
    fetch("/create-setup-intent") // Make sure this endpoint is set up on your backend
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  return (
    <div className="flex-col items-center justify-center gap-4 p-10">
      <h2 className="mb-4 text-xl">Setup Payment Method</h2>
      <Elements stripe={stripePromise}>
        <CardSetupForm
          clientSecret={
            "seti_1Q8PHJImDgfPt27S7bP85laX_secret_R0Q7VgBfhAmfMb7i57OFuiVeCz8S6yL"
          }
        />
      </Elements>
    </div>
  );
};

export default PaymentSetup;
