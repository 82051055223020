/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext } from "react";

const GlobalContext = createContext({});

export const GlobalProvider = (props) => {
  return (
    <GlobalContext.Provider value={{}}>{props.children}</GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};
