/* eslint-disable react-hooks/exhaustive-deps */
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import axios from "../../axios";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { useState, useEffect } from "react";

const useCalculateAmount = ({ currency, setCurrency }) => {
  const { subscriptionId, setStatus } = useSubscriptionContext();

  const [amount, setAmount] = useState();
  const [amountLoading, setAmountLoading] = useState(true);

  useEffect(() => {
    const getCalculatedAmount = async () => {
      setAmountLoading(true);
      try {
        const { data } = await axios.post(
          `/subscription/calculate-amount/${subscriptionId}`,
          { currency: currency }
        );

        setAmount(data.amount);
        setCurrency(data.currency);

        await new Promise((resolve) => setTimeout(resolve, 100));
      } catch (error) {
        ErrorHandler(error, setStatus);
      } finally {
        setAmountLoading(false);
      }
    };

    getCalculatedAmount();
  }, [currency]);

  return { amount, amountLoading };
};

export default useCalculateAmount;
