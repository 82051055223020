/* eslint-disable react-hooks/exhaustive-deps */
import axios from "../../axios";
import { fieldNames } from "../../constants/fieldNames";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { Country } from "country-state-city";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { useEffect, useState } from "react";

const usePrefetchCurrencies = () => {
  const {
    xIntentId,
    currency,
    setCurrency,
    setStatus,
    globalIntentData,
    setGlobalIntentData,
    merchantName,
    setMerchantName,
    setLoading,
    setLoadingMain,
    setMode,
  } = useOneTimePaymentsContext();

  const [currenciesOptions, setCurrenciesOptions] = useState([]);
  const [currenciesOptionsList, setCurrenciesOptionsList] = useState([]);

  const getCurrencySymbol = (currency) => {
    let symbol = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
    }).format(0);

    symbol = symbol.replace(/\d/g, "");

    return currency === symbol.slice(0, -1)
      ? currency
      : `${currency} (${symbol})`;
  };

  useEffect(() => {
    const getPrefetchCurrencies = async () => {
      setLoadingMain(true);
      try {
        const { data } = await axios.post(
          `/payments/v2/prefetchCurrencies/${xIntentId}`
        );

        data.secondary && data.secondary.length > 0
          ? setCurrency(data.secondary[0])
          : setCurrency(data?.primary?.currency);

        setMerchantName(data?.merchantName);
        setMode(data?.mode);

        let currenciesOptionsList = [];
        let currenciesOptions = [];

        setGlobalIntentData({
          ...globalIntentData,
          [fieldNames.email]: data?.email,
          [fieldNames.pincode]: data?.pinCode,
          [fieldNames.country]: data?.country
            ? data?.country +
              "-" +
              Country.getCountryByCode(data?.country)?.name
            : null,
        });

        if (data.secondary && data.secondary.length > 0) {
          currenciesOptions.push({
            label: getCurrencySymbol(data.secondary[0]),
            value: data.secondary[0],
          });
        }
        currenciesOptions.push({
          label: getCurrencySymbol(data.primary.currency),
          value: data.primary.currency,
        });

        if (data.secondary && data.secondary.length > 0) {
          currenciesOptionsList.push({
            label: getCurrencySymbol(data.secondary[0]),
            value: data.secondary[0],
          });
        }

        currenciesOptionsList.push({
          label: getCurrencySymbol(data.primary.currency),
          value: data.primary.currency,
        });

        data.others.sort();

        data.others.forEach((element) => {
          currenciesOptionsList.push({
            label: getCurrencySymbol(element),
            value: element,
          });
        });

        setCurrenciesOptions(currenciesOptions);
        setCurrenciesOptionsList(currenciesOptionsList);

        setLoading(false);
        await new Promise((resolve) => setTimeout(resolve, 100));
      } catch (error) {
        ErrorHandler(error, setStatus);
      } finally {
        setLoadingMain(false);
      }
    };
    getPrefetchCurrencies();
  }, []);

  return {
    currency,
    merchantName,
    currenciesOptions,
    currenciesOptionsList,
    setCurrenciesOptions,
  };
};

export default usePrefetchCurrencies;
