/* eslint-disable react-hooks/exhaustive-deps */
import { Divider } from "antd";
import { useState } from "react";
import CustomCurrencyField from "../UI/CustomCurrencyField";
import { ImSpinner2 } from "react-icons/im";
import useCalculateAmount from "../../hooks/OneTimePayment/useCalculateAmount";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";

const TransDetails = ({
  currency,
  setCurrency,
  merchantName,
  currenciesOptions,
  currenciesOptionsList,
  setCurrenciesOptions,
}) => {
  const { step } = useOneTimePaymentsContext();
  const [radioValue, setRadioValue] = useState(currency);
  const { amountLoading, amount } = useCalculateAmount({
    currency: radioValue,
    setCurrency: setRadioValue,
  });

  return (
    <div>
      <Divider />
      {step === 0 && (
        <CustomCurrencyField
          currency={currency}
          setCurrency={setCurrency}
          radioValue={radioValue}
          setRadioValue={setRadioValue}
          currenciesOptions={currenciesOptions}
          currenciesOptionsList={currenciesOptionsList}
          setCurrenciesOptions={setCurrenciesOptions}
        />
      )}
      <div className="flex items-center justify-between gap-4">
        <div>
          <span className="">Paying to</span>{" "}
          <span className="font-semibold">{merchantName}</span>
        </div>
        <div className="text-right">
          {amountLoading ? (
            <div className="flex items-center justify-center text-sm">
              <ImSpinner2 className="mr-2 animate-spin" />
              <span className="animate-pulse">Calculating...</span>
            </div>
          ) : (
            <div className="font-semibold">
              {Intl.NumberFormat("en-US", {
                style: "currency",
                currency: currency,
                minimumFractionDigits: 2,
              }).format(amount / 100)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransDetails;
