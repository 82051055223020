import { SentryRoutes } from "./v2/utils/initSentry";
import { BrowserRouter, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { GlobalProvider } from "./v2/contexts/GlobalContext";

import GlobalErrorBoundary from "./v2/utils/GlobalErrorBoundary";
import PaymentConfirmation from "./v2/components/OneTimePayment/PaymentLinks/PaymentConfirmation/PaymentConfirmationScreen";
import PaymentExpired from "./v2/components/OneTimePayment/PaymentLinks/PaymentExpired";
import PaymentCompleted from "./v2/components/OneTimePayment/PaymentLinks/PaymentCompleted";
import OneTimePayments from "./v2/OneTimePayments";
import Subscriptions from "./v2/Subscriptions";
import FeatureFlag from "./FeatureFlag";
import PaymentSetup from "./v2/subscriptionPOC/PaymentSetup";
import AntDesignContext from "./v2/contexts/AntDesignContext";

const App = () => {
  return (
    <GlobalProvider>
      <GlobalErrorBoundary>
        <AntDesignContext>
          <BrowserRouter>
            <SentryRoutes>
              <Route path="/" element={<FeatureFlag />} />

              {/* V2 path */}
              <Route path="/v2" element={<OneTimePayments />} />
              <Route path="/v2/subscription" element={<Subscriptions />} />
              <Route path="/subs/poc" element={<PaymentSetup />} />

              {/* Payment Links Routes  */}
              <Route path="/confirmation" element={<PaymentConfirmation />} />
              <Route
                path="/confirmation/subscription"
                element={<PaymentConfirmation />}
              />
              <Route path="/links/completed" element={<PaymentCompleted />} />
              <Route path="/links/expired" element={<PaymentExpired />} />
            </SentryRoutes>
          </BrowserRouter>
          <Toaster />
        </AntDesignContext>
      </GlobalErrorBoundary>
    </GlobalProvider>
  );
};

export default App;
