/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { IoCloseCircleSharp } from "react-icons/io5";
import { Modal } from "antd";

const BackButton = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBack = () => {
    if (window.history.length > 2) {
      window.history.go(-3);
    } else {
      window.close();
    }
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);

    const handlePopState = (event) => {
      event.preventDefault();
      window.history.pushState(null, "", window.location.href);
      handleClickOpen();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <div>
      <Modal
        title="Are you sure you want to quit?"
        open={open}
        onOk={handleBack}
        onCancel={handleClose}
        centered
        width={
          typeof window !== "undefined" && window.innerWidth < 764 ? 350 : 450
        }
      ></Modal>
      <IoCloseCircleSharp
        className="text-bold text-xl absolute right-[-30px] sm:right-[-40px] top-2 cursor-pointer hover:opacity-50"
        onClick={handleClickOpen}
      />
    </div>
  );
};

export default BackButton;
