/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "../../axios";
import { ErrorHandler } from "../../utils/ErrorHandler";

export const useGetIntent = ({ xIntentId }) => {
  const [status, setStatus] = useState();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const getIntent = async () => {
      setLoader(true);
      try {
        const { data } = await axios.get(`/payments/get-intent/${xIntentId}`);
        setStatus(data.status);
        await new Promise((resolve) => setTimeout(resolve, 100));
      } catch (error) {
        ErrorHandler(error, setStatus);
      } finally {
        setLoader(false);
      }
    };

    getIntent();
  }, []);

  return { status, loader };
};
