/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Radio, Select } from "antd";

const CustomCurrencyField = ({
  currency,
  setCurrency,
  radioValue,
  setRadioValue,
  currenciesOptions,
  currenciesOptionsList,
  setCurrenciesOptions,
}) => {
  const [optionValue, setOptionValue] = useState(currency);

  useEffect(() => {
    setRadioValue(currency);
    setOptionValue(currency);
  }, [currenciesOptions]);

  const onChange = ({ target: { value } }) => {
    setRadioValue(value);
    setCurrency(value);
    setOptionValue(value);
  };

  const onChange2 = (value, option) => {
    setOptionValue(value);
    setCurrency(value);

    if (value === currenciesOptions[0].value) {
      setRadioValue(value);

      if (currenciesOptions.length - 1 === 0) {
        currenciesOptions[currenciesOptions.length - 1].label = option.label;
        currenciesOptions[currenciesOptions.length - 1].value = value;
      }

      return;
    }

    if (value !== radioValue) {
      currenciesOptions[currenciesOptions.length - 1].label = option.label;
      currenciesOptions[currenciesOptions.length - 1].value = value;

      setCurrenciesOptions(currenciesOptions);
      setRadioValue(value);
    }
  };

  return (
    <div className="flex justify-end gap-0 pb-6">
      <div className="flex gap-0" id="currency">
        <Radio.Group
          size="middle"
          options={currenciesOptions}
          onChange={onChange}
          value={radioValue}
          optionType="button"
          buttonStyle="outline"
          className="w-full"
        />
        <div className="w-fit" id="currency">
          <Select
            options={currenciesOptionsList}
            size="middle"
            onChange={onChange2}
            value={optionValue}
            className="no-input w-[35px]"
            popupMatchSelectWidth={false}
            showSearch
          />
        </div>
      </div>
    </div>
  );
};

export default CustomCurrencyField;
