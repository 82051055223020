/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import PaymentStatusSuccess from "./PaymentStatusSuccess";
import PaymentStatusFailed from "./PaymentStatusFailed";
import PaymentStatusRefunded from "./PaymentStatusRefunded";
import PaymentStatusView from "../../../OneTimePayment/PaymentStatusView";
import { useGetIntent } from "../../../../hooks/OneTimePayment/useGetIntent";
import GridBackground from "../../../UI/GridBackground";
import LandingWrapper from "../../../UI/LandingWrapper";
import { ImSpinner2 } from "react-icons/im";

const PaymentConfirmationScreen = () => {
  const urlParam = new URLSearchParams(window.location.search);
  const xIntentId = useState(urlParam.get("xpay_intent_id"));

  const { status, loader } = useGetIntent({ xIntentId });

  const renderContent = () => {
    switch (status) {
      case "SUCCESS":
        return <PaymentStatusSuccess />;
      case "REFUNDED":
        return <PaymentStatusRefunded />;
      case "FAILED":
      case "INCOMPLETE":
      case "CREATED":
        return <PaymentStatusFailed />;
      case "LOADING":
      case "ERROR":
      default:
        return <PaymentStatusView />;
    }
  };

  return (
    <GridBackground>
      {loader ? (
        <div className="flex items-center justify-center h-[80vh] text-3xl">
          <ImSpinner2 className="animate-spin" />
        </div>
      ) : (
        <LandingWrapper>{renderContent()}</LandingWrapper>
      )}
    </GridBackground>
  );
};

export default PaymentConfirmationScreen;
