import Loader from "../../../UI/Loader";
import successAnimation from "../../../../assets/Animations/successAnimation.json";

const PaymentStatusSuccess = () => {
  return (
    <div className="pt-8 payment-confirmation-cont stack">
      <Loader
        json={successAnimation}
        width="500px"
        height="250px"
        loop={false}
        keepLastFrame={true}
        mobileHeight="250px"
        mobileWidth="500px"
      />
      <div className="pt-8 text-lg">Thank you for your payment!</div>
    </div>
  );
};

export default PaymentStatusSuccess;
