/* eslint-disable react-hooks/exhaustive-deps */

import { createContext, useContext, useEffect, useState } from "react";

const SubscriptionContext = createContext({});

export const SubscriptionProvider = (props) => {
  const [step, setStep] = useState(0);
  const [form, setForm] = useState();
  const [amount, setAmount] = useState();
  const [merchantName, setMerchantName] = useState();
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [loadingMain, setLoadingMain] = useState(true);
  const [loading, setLoading] = useState(true);
  const [stripe, setStripe] = useState();
  const [stripeElement, setStripeElement] = useState();
  const [stripeCardElement, setStripeCardElement] = useState();
  const [stripeFormFilled, setStripeFormFilled] = useState(false); // TODO: revise this
  const [paymentError, setPaymentError] = useState(false);
  const [status, setStatus] = useState(null);
  const [mode, setMode] = useState();
  const urlParam = new URLSearchParams(window.location.search);
  const [subscriptionId, setSubscriptionId] = useState(
    urlParam.get("subscription_id")
  );
  const [currency, setCurrency] = useState(urlParam.get("currency") || "USD");
  const [globalIntentData, setGlobalIntentData] = useState({
    paymentMethod: urlParam.get("paymentMethod"),
  });
  const [CSCProps, setCSCProps] = useState();
  const [interval, setInterval] = useState();
  const [intervalCount, setIntervalCount] = useState(0);
  const [pg, setPg] = useState();
  const [callbackUrl, setCallbackUrl] = useState();
  const [pgPublicKey, setPgPublicKey] = useState();
  const [clientSecret, setClientSecret] = useState();

  useEffect(() => {
    setPaymentError(null);
    setGlobalIntentData({
      ...globalIntentData,
      cardName: null,
      cardNumber: null,
      cardValidity: null,
      cardSecurityCode: null,
    });
  }, [step]);

  return (
    <SubscriptionContext.Provider
      value={{
        step,
        setStep,
        form,
        setForm,
        currency,
        setCurrency,
        amount,
        setAmount,
        globalIntentData,
        setGlobalIntentData,
        subscriptionId,
        setSubscriptionId,
        paymentMethodList,
        setPaymentMethodList,
        merchantName,
        setMerchantName,
        loading,
        setLoading,
        loadingMain,
        setLoadingMain,
        stripe,
        setStripe,
        stripeElement,
        setStripeElement,
        stripeFormFilled,
        setStripeFormFilled,
        paymentError,
        setPaymentError,
        status,
        setStatus,
        mode,
        setMode,
        CSCProps,
        setCSCProps,
        interval,
        setInterval,
        pg,
        setPg,
        intervalCount,
        setIntervalCount,
        callbackUrl,
        setCallbackUrl,
        pgPublicKey,
        setPgPublicKey,
        clientSecret,
        setClientSecret,
        stripeCardElement,
        setStripeCardElement,
      }}
    >
      {props.children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionContext = () => {
  return useContext(SubscriptionContext);
};
